import React, { useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { ParlorDetailProps } from "../../../types";
import { ColorIcon } from "../../common/Icons/ColorIcon";
import { colors } from "../../../styles";
import { FUNERAL_SIDO } from "../../../constants";

import parlors from "../../../constants/parlors_detail.json";

export function CTypeFAQ({
  defaultParlor,
}: {
  defaultParlor: ParlorDetailProps;
}) {
  const managedTypeEqualParlors = parlors.filter(
    (res) =>
      res.managed_type === defaultParlor.managed_type &&
      res.sigungu === defaultParlor.sigungu
  );

  const currentParlorAverageBinsoPrice = defaultParlor.binso_average_charge;
  const [averageBinsosPrice, setAverageBinsosPrice] = useState(0);

  useEffect(() => {
    let price = 0;

    managedTypeEqualParlors.map((res) => {
      for (let i = 0; i < managedTypeEqualParlors.length - 1; i++) {
        if (typeof res.binso_average_charge === "number")
          price = price + Number(res.binso_average_charge);
      }
    });

    setAverageBinsosPrice(price / managedTypeEqualParlors.length);
  }, []);

  const isCurrentBinsoPriceExpensive = useMemo(() => {
    return currentParlorAverageBinsoPrice > averageBinsosPrice;
  }, [currentParlorAverageBinsoPrice, averageBinsosPrice]);

  const binsoPercent = isCurrentBinsoPriceExpensive
    ? (
        -(1 - currentParlorAverageBinsoPrice / averageBinsosPrice) * 100
      ).toFixed(0)
    : ((1 - currentParlorAverageBinsoPrice / averageBinsosPrice) * 100).toFixed(
        0
      );

  const commonMessage = `${defaultParlor.companyname}은 ${FUNERAL_SIDO.get(
    Number(defaultParlor.sido)
  )}의 ${
    defaultParlor.managed_type
  } 장례식장 평균에 비해 ${binsoPercent}% 정도 ${
    isCurrentBinsoPriceExpensive ? "가격이 높습니다" : "저렴합니다"
  }`;

  const parlor16Message = `쉴낙원 홍천 장례식장은 인접한 장례식장에 비해 개별반찬 구매시 양이 많기에 음식반찬 비용이 저렴한 편입니다.`;

  const isHongcheonParlor = Number(defaultParlor.id) === 161;

  return (
    <Stack gap="16px" padding="0px 0px 10px">
      <Box className="subtitle_18_b">자주 묻는 질문 FAQ</Box>
      <Stack gap="32px">
        <Stack gap="4px">
          <Stack flexDirection="row" gap="5px">
            <ColorIcon
              name="downArrow"
              size={16}
              style={{ marginTop: "2px" }}
              color={colors.gray600}
            />
            <Box className="subtitle_14_b gray900">
              조문객이 없을 것 같은데 빈소를 차리지 않으면 장례식장 비용은
              0원인가요?
            </Box>
          </Stack>
          <Box component="p" className="body_14_m gray700">
            그렇지 않습니다.
            <br />
            장례식장 비용은 빈소, 접객실 이용료 외에도 다양하게 구성되어
            있습니다. 자세한 비용을 알고 싶다면 전문가와의 상담을 추천드립니다.
            또한 모든 장례식장이 빈소를 차리지 않는 고객을 받아 주는 것은 아니기
            때문에 더욱 더 상담이 필요합니다.
          </Box>
        </Stack>
        <Stack gap="4px">
          <Box component="p" className="subtitle_14_b gray900">
            Q. {defaultParlor.companyname}은 저렴한 편에 속하나요?
          </Box>
          <Box className="body_14_m gray700">
            {isHongcheonParlor ? parlor16Message : commonMessage}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
