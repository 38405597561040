export const excludeParlorList = [
  "76",
  "247",
  "325",
  "595",
  "611",
  "641",
  "792",
  "996",
];
